import { Node, Edge, isNode, getIncomers } from "react-flow-renderer";
import Workflow from "../../../../models/workflow/backend/Workflow.model";
import WorkflowCondition, {
  ConnectionHandles,
  WorkflowConditionConfiguration,
} from "../../../../models/workflow/backend/WorkFlowCondition.model";
import WorkflowStep, {
  WorkflowStepType,
} from "../../../../models/workflow/backend/WorkflowStep.model";
// import StageViewState from "../../../models/workflow/constants/StageViewState.constant";
// import WorkflowFrontendCondition from "../../../models/workflow/frontend/WorkflowFrontendCondition.model";
import { WorkFlowNode } from "../../../../models/workflow/WorkflowNode.model";
import WorkflowReviewStage from "../../../../models/workflow/WorkflowReviewStage.model";
import WorkflowStage from "../../../../models/workflow/WorkflowStage.model";
import removeWhitespaces from "../../../utils/removeWhitespaces";
import { parseExpressionUnitList } from "../../calculatedfield/utils/transform";

const parseToBackend = (
  elements: any,
  partialWorkflow: Workflow,
  defaultFormId: number,
  isEdit: boolean
): Workflow => {
  const nodes: Node[] = elements.filter((element: Node | Edge) =>
    isNode(element)
  ) as Node[];

  const edges: Edge[] = elements.filter(
    (element: Node | Edge) => !isNode(element)
  ) as Edge[];

  const steps: WorkflowStep[] = [];
  const stages: WorkflowStage[] = [];
  const conditions: WorkflowCondition[] = [];

  const root = nodes.find(
    (node) => getIncomers(node, elements).length === 0 //TO DO NEED TO CHECK
  );
  if (!root) {
    throw "Needs to be one root node";
  }
  const rootStageName = getNodeName(partialWorkflow, isEdit, root);

  nodes.forEach((node: Node, index: number) => {
    if ((node.data as WorkFlowNode).configuration.type === "Condition") {
      // Node type => condition
      const condition = getConditionData(
        node,
        partialWorkflow,
        edges,
        nodes,
        isEdit
      );

      const step = getStep(
        partialWorkflow,
        edges,
        node,
        nodes,
        isEdit,
        index,
        "ConditionIf"
      );
      conditions.push(condition);
      steps.push(step);
    } else {
      // Node type => stage or stage review
      const stageName = getNodeName(partialWorkflow, isEdit, node);

      const stageSteps = getStep(
        partialWorkflow,
        edges,
        node,
        nodes,
        isEdit,
        index,
        node.data.configuration.type
      );
      steps.push(stageSteps);

      const workflowStage = partialWorkflow.stages?.find(
        (stage) => stage.id?.toString() === node.id
      );
      stages.push(
        isEdit
          ? {
              ...getStageData(node, stageName, defaultFormId),
              id: workflowStage?.id,
            }
          : getStageData(node, stageName, defaultFormId)
      );
    }
  });
  return {
    ...partialWorkflow,
    conditions: conditions,
    stages: stages,
    steps: orderSteps(steps, rootStageName, conditions, 0),
  };
};

const orderSteps = (
  steps: WorkflowStep[],
  name: string,
  conditions: WorkflowCondition[],
  order: number
): WorkflowStep[] => {
  const step = steps.find((step) => step.name === name);

  if (step) {
    if (step.type === "ConditionIf") {
      const condition = conditions.find((condition) => condition.name === name);
      if (condition) {
        const firstBranch = orderSteps(
          steps,
          condition.stepResult1.substring(5),
          conditions,
          order + 1
        );

        const newOrder =
          (firstBranch[firstBranch.length - 1].order as number) + 1;
        const secondBranch = orderSteps(
          steps,
          condition.stepResult2.substring(5),
          conditions,
          newOrder as number
        );

        return [{ ...step, order: order }, ...firstBranch, ...secondBranch];
      }
    }
    if (!step.nextStepName) {
      return [{ ...step, order: order }];
    }
    if (["Stage", "StageReview"].includes(step.type)) {
      return [
        { ...step, order: order },
        ...orderSteps(steps, step.nextStepName, conditions, order + 1),
      ];
    }
  }
  return [];
};

const getStep = (
  workflow: Workflow,
  edges: Edge[],
  node: Node,
  nodes: Node[],
  isEdit: boolean,
  currentOrder: number,
  stepType: WorkflowStepType
): WorkflowStep => {
  const nodeName = getNodeName(workflow, isEdit, node);
  const step: WorkflowStep = {
    name: nodeName,
    displayName: node.data.displayName,
    type: stepType,
    order: currentOrder,
  };
  const connection = edges.find((edge: Edge) => edge.source === node.id);
  if (connection) {
    const next = nodes.find(
      (nextNode: Node) => nextNode.id === connection.target
    );
    return {
      ...step,
      nextStepName:
        next && stepType !== "ConditionIf"
          ? getNodeName(workflow, isEdit, next)
          : "",

      configuration: {
        sourceHandle: connection.sourceHandle,
        targetHandle: connection.targetHandle,
      },
    };
  } else {
    return step;
  }
};

const getConditionData = (
  node: Node,
  workflow: Workflow,
  edges: Edge[],
  nodes: Node[],
  isEdit: boolean
): WorkflowCondition => {
  const found = edges.filter((edge) => edge.source === node.id);
  // TODO: validation to make sure a condition has two edges.
  const condition: WorkflowCondition = {
    name: getNodeName(workflow, false, node),
    displayName: node.data.displayName,
    type: "If",
    expression1: parseExpressionUnitList(node.data.expressionList),
    stepResult1: `Step:${getStepResult(found[0], nodes, workflow, isEdit)}`,
    stepResult2: `Step:${getStepResult(found[1], nodes, workflow, isEdit)}`,
    version: 1,
    configuration: getConditionConfiguration(edges, node),
  };
  return condition;
};

const getConditionConfiguration = (
  edges: Edge[],
  node: Node
): WorkflowConditionConfiguration => {
  const connections = edges.filter((edge: Edge) => edge.source === node.id);

  if (connections) {
    const first = connections.find((connection) => connection.label === "true");
    const step1Handles: ConnectionHandles = {
      sourceHandle: first?.sourceHandle as string,
      targetHandle: first?.targetHandle as string,
    };
    const second = connections.find(
      (connection) => connection.label === "false"
    );
    const step2Handles: ConnectionHandles = {
      sourceHandle: second?.sourceHandle as string,
      targetHandle: second?.targetHandle as string,
    };

    return {
      ...node.data.configuration,
      position: node.position,
      step1Handles: step1Handles,
      step2Handles: step2Handles,
    };
  }
  throw "Condition valid connections not found"; /// TODO deal with validation
};
const getStepResult = (
  edge: Edge,
  nodes: Node[],
  workflow: Workflow,
  isEdit: boolean
) => {
  const target = nodes.find((node) => node.id === edge.target);
  if (target) {
    return getNodeName(workflow, isEdit, target);
  }
};

const getNodeName = (
  workflow: Workflow,
  isEdit: boolean,
  node: Node
): string => {
  if (isEdit) {
    const workflowNodes: WorkFlowNode[] = (
      workflow.stages ?? ([] as WorkFlowNode[])
    ).concat(workflow.conditions ?? ([] as WorkFlowNode[]));
    const workflowNode = workflowNodes.find(
      (stage) => stage.id?.toString() === node.id
    );
    return workflowNode?.name
      ? workflowNode.name
      : removeWhitespaces(node.data.displayName);
  } else {
    return removeWhitespaces(
      node.data.displayName ? node.data.displayName : node.data.name
    );
  }
};
const getStageData = (
  node: Node,
  stageName: string,
  defaultFormId: number
): WorkflowStage | WorkflowReviewStage => {
  const baseStage = {
    name: stageName,
    configuration: {
      ...(node.data as WorkflowStage).configuration,
      position: node.position,
    },
    formId: (node.data as WorkflowStage).formId
      ? (node.data as WorkflowStage).formId
      : defaultFormId,
    displayName: node.data.displayName,
    autoProgress: node.data.autoProgress,
    events: node.data.events,
    viewState: node.data.viewState,
    readOnly: node.data.readOnly,
  };

  return node.type === "StageReview"
    ? {
        ...baseStage,
        hasReview: true,
        allowManagersApproval: node.data.allowManagersApproval,
        tasks: node.data.tasks,
        reviewers: node.data.reviewers
          ? (node.data.reviewers as any[]).map((reviewer) => ({
              entityId: reviewer.entityId as string,
            }))
          : [],
        requiredApproversCount: node.data.requiredApproversCount,
      }
    : baseStage;
};
export default parseToBackend;
