import { Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectWorkflow,
  updateWorkflowDescription,
  updateWorkflowName,
} from "../../../../features/workflow/workflowSlice";
import { useAppSelector } from "../../../../store/hooks";
import EdisonTypography from "../../../../../app-shared/edison/typography/EdisonTypography";

const WorkflowInformation: FC = () => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useDispatch();
  const workflow = useAppSelector(selectWorkflow);
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column">
        <EdisonTypography
          variant="t7"
          disableUppercase
          title={t("workflowName")}
        />
        <TextField
          size="small"
          className="no-label-input"
          value={workflow.name}
          onChange={(e) => dispatch(updateWorkflowName(e.target.value))}
        />
      </Stack>

      <Stack direction="column">
        <EdisonTypography
          variant="t7"
          disableUppercase
          title={t("description")}
        />
        <TextField
          size="small"
          className="no-label-input"
          value={workflow.description}
          onChange={(e) => dispatch(updateWorkflowDescription(e.target.value))}
        />
      </Stack>
    </Stack>
  );
};

export default WorkflowInformation;
