import { Edge } from "react-flow-renderer";
import { defaultWorkflowConnection } from "../../../../models/workflow/constants/Node.constant";
import { WorkFlowNode } from "../../../../models/workflow/WorkflowNode.model";

const getValidConnection = (elements: any[], params: any): Edge => {
  const source = elements.find((elem) => elem.id === params.source);
  if ((source.data as WorkFlowNode).configuration.type === "Condition") {
    const branches = elements.filter((elem) => elem.source === source.id);
    if (branches.length === 0) {
      return { ...defaultWorkflowConnection, ...params, label: "true" };
    }
    if (branches.length === 1) {
      if (branches[0].label === "true") {
        return {
          ...defaultWorkflowConnection,
          ...params,
          label: "false",
        };
      }
      return {
        ...defaultWorkflowConnection,
        ...params,
        label: "true",
      };
    }
  }
  return { ...defaultWorkflowConnection, ...params };
};
export default getValidConnection;
