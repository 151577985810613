import { nanoid } from "nanoid";
import { addEdge, Edge, Node } from "react-flow-renderer";
import Item from "../../../../models/Item.model";
import Workflow from "../../../../models/workflow/backend/Workflow.model";
import WorkflowCondition from "../../../../models/workflow/backend/WorkFlowCondition.model";
import WorkflowStep from "../../../../models/workflow/backend/WorkflowStep.model";
import {
  defaultWorkflowConnection,
  nodeWidth,
} from "../../../../models/workflow/constants/Node.constant";
import { WorkFlowNode } from "../../../../models/workflow/WorkflowNode.model";
import WorkflowStage from "../../../../models/workflow/WorkflowStage.model";
import { parseExpressionUnitListString } from "../../calculatedfield/utils/transform";

const parseToFrontend = (
  workflow: Workflow,
  fields: Item[]
): (Node | Edge)[] => {
  let elements: (Node | Edge)[] = [];
  const workflowNodes = (workflow.stages as WorkFlowNode[]).concat(
    workflow.conditions as WorkFlowNode[]
  );
  workflow.stages?.forEach((stage: WorkflowStage) => {
    const node: Node = {
      id: stage.id ? stage.id.toString() : "",
      type: stage.configuration.type,
      position: stage.configuration.position,
      data: {
        ...stage,
      },
      style: {
        border: `2px solid ${stage.configuration.color}`,
        borderRadius: "4px",
        minWidth: nodeWidth,
      },
    };
    elements.push(node);
  });

  workflow.conditions?.forEach((condition: WorkflowCondition) => {
    const node: Node = {
      id: condition.id ? condition.id.toString() : "",
      type: "Condition",
      position: condition.configuration.position,
      data: {
        ...condition,
        expressionList: parseExpressionUnitListString(
          condition.expression1,
          fields
        ),
      },
      style: {
        border: `2px solid ${condition.configuration.color}`,
        borderRadius: "4px",
        minWidth: nodeWidth,
      },
    };
    elements.push(node);

    const edge1 = getEdge(
      { ...defaultWorkflowConnection, label: "true" },
      workflowNodes,
      condition.name,
      condition.stepResult1.substring(5),
      condition.configuration.step1Handles.sourceHandle,
      condition.configuration.step1Handles.targetHandle
    );
    const edge2 = getEdge(
      { ...defaultWorkflowConnection, label: "false" },
      workflowNodes,
      condition.name,
      condition.stepResult2.substring(5),
      condition.configuration.step2Handles.sourceHandle,
      condition.configuration.step2Handles.targetHandle
    );

    elements = addEdge(edge1, elements);
    elements = addEdge(edge2, elements);
  });

  workflow.steps?.forEach((step: WorkflowStep) => {
    if (step.nextStepName) {
      const edge = getEdge(
        defaultWorkflowConnection,
        workflowNodes,
        step.name,
        step.nextStepName,
        step.configuration.sourceHandle,
        step.configuration.targetHandle
      );
      elements = addEdge(edge, elements);
    }
  });
  return elements;
};

const getEdge = (
  baseEdge: Partial<Edge>,
  workflowNodes: WorkFlowNode[],
  sourceName: string,
  targetName: string,
  sourceHandle: string,
  targetHandle: string
): Edge => {
  const sourceId = workflowNodes?.find(
    (stage) => stage.name === sourceName
  )?.id;
  const targetId = workflowNodes?.find(
    (stage) => stage.name === targetName
  )?.id;
  return {
    ...baseEdge,
    source: sourceId?.toString() as string,
    target: targetId?.toString() as string,
    id: nanoid(),
    sourceHandle: sourceHandle,
    targetHandle: targetHandle,
  };
};
export default parseToFrontend;
