import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ExpandableMenu from "../../components/expandableMenu/ExpandableMenu";
import Features from "../../components/features/Features";
import FormDesignerProperties from "../../components/formdesignerproperties/FormDesignerProperties";
import Layout from "../../components/layout/Layout";
import LivePreview from "../../components/livePreview/LivePreview";
import { section } from "../../config/constants";
import { getFieldsAsync } from "../../features/fields/fieldsSlice";
import { setIsEdit } from "../../features/livepreview/livepreviewSlice";
import {
  getTablesAsync,
  selectTableStatus,
} from "../../features/tables/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import "./formdesigner.scss";

const FormDesigner: FC = () => {
  const history = useHistory();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);
  const location: any = useLocation();
  const cancelOperation = () => {
    history.push({
      pathname: "/admin/fields",
      state: { title: t("fields") },
    });
  };

  useEffect(() => {
    location.state?.configuration
      ? dispatch(setIsEdit(location.state.configuration.isEdit))
      : dispatch(setIsEdit(true));
  }, [location]);

  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );
  const tablesStatus = useAppSelector(selectTableStatus);

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
    if (tablesStatus === "idle") {
      dispatch(getTablesAsync());
    }
  }, []);

  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <FormProvider {...methods}>
          <form>
            <Grid className="form-designer-page-root" container spacing={3}>
              <Grid item xs={3} className="form-designer-page-root__item">
                <ExpandableMenu title={t("properties")}>
                  <FormDesignerProperties />
                </ExpandableMenu>
                <ExpandableMenu title={t("features")} sticky>
                  <Features
                    tabs={section.map(({ id, name }) => ({ id, label: name }))}
                  />
                </ExpandableMenu>
              </Grid>
              <Grid item xs={9} className="form-designer-page-root__item">
                <LivePreview form={null}></LivePreview>
              </Grid>
            </Grid>
            <div className="form-designer-button-container">
              <EdisonButton onClick={cancelOperation} name={t("cancel")} />
              <EdisonButton type="submit" name={t("save")} />
            </div>
          </form>
        </FormProvider>
      </DndProvider>
    </Layout>
  );
};
export default FormDesigner;
