import { User } from "@microsoft/microsoft-graph-types";
import {
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  selectGroupMembers,
  selectUserPhotos,
} from "../../../../../features/msGraph/msGraphSlice";
import React, { useEffect } from "react";
import { Node } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectCurrentElement,
  selectIsEdit,
  updateElement,
} from "../../../../features/workflow/workflowSlice";
import UserPicture from "../../../../../models/common/UserPicture.model";
import StageViewState from "../../../../../models/workflow/constants/StageViewState.constant";
import { useAppSelector } from "../../../../store/hooks";
import UserPeoplePicker from "../../../../../app-shared/user/peoplepicker/UserPeoplePicker";
import removeWhitespaces from "../../../../utils/removeWhitespaces";
import ExpandableMenu from "../../../expandableMenu/ExpandableMenu";
import updateNodeCustomData from "../../utils/updateNodeCustomData";
import { getUserByEntityId, mapPeople } from "../../utils/userMapper";
const NodeStage = () => {
  const { t } = useTranslation(["admin"]);
  const groupMembers: User[] = useAppSelector(selectGroupMembers);
  const userPictures: UserPicture[] = useAppSelector(selectUserPhotos);
  const currentNode = useAppSelector(selectCurrentElement);
  const isEdit = useAppSelector(selectIsEdit);
  const dispatch = useDispatch();

  return (
    <ExpandableMenu title={t("stages")}>
      <Stack direction="column" spacing={2}>
        <Stack direction="column">
          <Typography> {t("label")}</Typography>
          <TextField
            size="small"
            className="no-label-input"
            value={currentNode ? currentNode.data.displayName : ""}
            onChange={(e) => {
              if (!currentNode) return;
              dispatch(
                updateElement({
                  ...currentNode,
                  data: { ...currentNode.data, displayName: e.target.value },
                })
              );
            }}
          />
        </Stack>
        <Stack direction="column">
          <Typography> {t("internalName")}</Typography>
          <TextField
            size="small"
            disabled={true}
            className="no-label-input"
            value={
              isEdit
                ? currentNode?.data.name
                : currentNode
                ? removeWhitespaces(currentNode.data.displayName)
                : ""
            }
          />
        </Stack>
        <Stack>
          <Typography> {t("visibility")}</Typography>
          <Select
            className="no-label-input"
            variant="outlined"
            size="small"
            value={currentNode?.data.viewState ? currentNode.data.viewState : 0}
            onChange={(e: any) => {
              if (!currentNode) return;
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "viewState",
                    e.target.value,
                    currentNode as Node
                  )
                )
              );
            }}>
            {Object.keys(StageViewState)
              .filter((item) => !isNaN(Number(item)))
              .map((state, index: number) => (
                <MenuItem key={index} value={state}>
                  {StageViewState[Number(state)]}
                </MenuItem>
              ))}
          </Select>
        </Stack>

        {currentNode && currentNode.data.configuration.type === "StageReview" && (
          <Stack direction="column" spacing={2}>
            <UserPeoplePicker
              multiple={true}
              label={t("reviewers")}
              placeholder={t("reviewers")}
              isEditing={true}
              noOptionsText={t("noReviewersMessage")}
              maxAvatars={4}
              maxDropdownCount={10}
              peopleList={mapPeople(userPictures, groupMembers)}
              value={
                currentNode?.data?.reviewers
                  ? getUserByEntityId(
                      currentNode.data.reviewers,
                      userPictures,
                      groupMembers
                    )
                  : []
              }
              onChange={(changeValue: any[]) => {
                if (!currentNode) return;
                dispatch(
                  updateElement(
                    updateNodeCustomData(
                      "reviewers",
                      changeValue.map((change) => ({
                        entityId: change.entityId,
                      })),
                      currentNode as Node
                    )
                  )
                );
              }}
              useInternalState={false}
            />
            <Stack direction="column">
              <Typography> {t("requiredApproversCount")}</Typography>
              <Input
                size="small"
                type="number"
                value={
                  currentNode?.data && currentNode.data.requiredApproversCount
                    ? currentNode.data.requiredApproversCount
                    : 0
                }
                onChange={(e) => {
                  if (!currentNode) return;
                  dispatch(
                    updateElement(
                      updateNodeCustomData(
                        "requiredApproversCount",
                        e.target.value,
                        currentNode as Node
                      )
                    )
                  );
                }}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </ExpandableMenu>
  );
};

export default NodeStage;
