import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "../../../App.scss";
import {
  getIndividualWorkflowAsync,
  getWorkflowsAsync,
  resetWorkflowSlice,
  selectChangeMade,
  selectWorkflow,
  selectWorkflowList,
  setElements,
  setIsEdit,
  setWorkflowToDefault,
} from "../../../features/workflow/workflowSlice";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import colors from "../../../theme/colors.module.scss";
import EdisonButton from "../../../../app-shared/edison/button/EdisonButton";
import CustomButton from "../../adminbutton/CustomButton";
import "./workflowselect.scss";

const WorkFlowSelect = () => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useAppDispatch();
  const workflowList = useAppSelector(selectWorkflowList);
  const currentWorkFlow = useAppSelector(selectWorkflow);
  const changeMade = useAppSelector(selectChangeMade);
  useEffect(() => {
    dispatch(getWorkflowsAsync());
  }, []);

  return (
    <Stack direction="row" className="workflow-select-root" spacing={1}>
      <div className="new-workflow-container">
        <CustomButton
          onClick={() => dispatch(resetWorkflowSlice(true))}
          innerText={t("newWorkflow")}
        />
      </div>
      <Select
        size="small"
        disabled={changeMade}
        variant="outlined"
        className="no-label-input workflow-select"
        style={{ backgroundColor: colors.white, textTransform: "uppercase" }}
        value={
          currentWorkFlow.name !== ""
            ? currentWorkFlow.name
            : workflowList.length > 0
            ? workflowList[0].name
            : ""
        }
        onChange={(e) => {
          if (e.target.value !== "AddWorkflow") {
            const workflow = workflowList.find(
              (flow) => flow.name === e.target.value
            );
            if (workflow?.id) {
              dispatch(setIsEdit(true));
              dispatch(getIndividualWorkflowAsync(workflow.id));
            }
          } else {
            dispatch(setIsEdit(false));
            dispatch(setElements([]));
            dispatch(setWorkflowToDefault());
          }
        }}>
        {workflowList.map((workflow, index) => (
          <MenuItem key={index} value={workflow.name}>
            {workflow.name}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default WorkFlowSelect;
