import { Button, Stack, Typography } from "@mui/material";
import dagre from "dagre";
import { postWorkflows, putWorkflows } from "../../../../services/APIService";
import React, { FC } from "react";
import ReactFlow, {
  Background,
  Controls,
  isNode,
  MiniMap,
  Node,
  Position,
  updateEdge,
  useStoreState,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { getAllForms } from "../../../features/forms/formsSlice";
import {
  addWorkflowEdge,
  addWorkflowNode,
  getIndividualWorkflowAsync,
  removeWorkflowNode,
  selectChangeMade,
  selectIsEdit,
  selectWorkflow,
  selectWorkflowElements,
  setDisplayEvent,
  setElements,
  setSelectedElement,
  setWorkflowOrientation,
} from "../../../features/workflow/workflowSlice";
import {
  nodeHeight,
  nodeTypes,
  nodeWidth,
} from "../../../../models/workflow/constants/Node.constant";
import { WorkflowNodeConfiguration } from "../../../../models/workflow/WorkflowNode.model";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import colors from "../../../theme/colors.module.scss";
import CustomButton from "../../adminbutton/CustomButton";
import CustomEdge from "../customedge/CustomEdge";
import { MarkerDefinition } from "../customedge/MarkerDefinitition";
import getNewNode from "../utils/getNewNode";
import parseToBackend from "../utils/parseToBackend";
import getValidConnection from "../utils/validateConnections";
import "./workflowzone.scss";

export interface WorkflowZoneProps {
  reactFlowInstance: any;
  onLoad: any;
  reactFlowWrapper: any;
}
let nodeIndex = 0;
export const getId = () => `dndnode_${nodeIndex++}`;
export const getNodeIndex = () => nodeIndex;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const WorkFlowZone: FC<WorkflowZoneProps> = ({
  onLoad,
  reactFlowInstance,
  reactFlowWrapper,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);
  const elements = useAppSelector(selectWorkflowElements);
  const workflow = useAppSelector(selectWorkflow);
  const forms = useAppSelector(getAllForms);
  const isEdit = useAppSelector(selectIsEdit);
  const changeMade = useAppSelector(selectChangeMade);

  const nodes = useStoreState((state: any) => state.nodes);

  const onConnect = (params: any) => {
    const connection = getValidConnection(elements, params);
    if (connection) {
      dispatch(addWorkflowEdge(connection));
    }
  };
  const onElementsRemove = (elementsToRemove: any) => {
    dispatch(removeWorkflowNode(elementsToRemove));
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onPaneClick = (event: any) => {
    dispatch(setSelectedElement(null));
  };
  const onElementClick = (event: any, clickedElement: any) => {
    const selectedNode = elements.find(
      (element: any) => element.id === clickedElement.id
    );
    dispatch(setSelectedElement(selectedNode));
    dispatch(setDisplayEvent(null));
  };

  const onDrop = (event: any) => {
    event.preventDefault();
    if (reactFlowWrapper && reactFlowWrapper.current && reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      if (type) {
        const config: WorkflowNodeConfiguration = JSON.parse(type);
        const newNode = getNewNode(config, position);
        dispatch(addWorkflowNode(newNode));
      }
    }
  };

  const getLayoutedElements = (elements: any, direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });
    elements.forEach((el: any) => {
      if (isNode(el)) {
        const fullNode = nodes.find((node: any) => node.id === el.id);
        dagreGraph.setNode(el.id, {
          width: fullNode?.__rf.width ?? nodeWidth,
          height: fullNode?.__rf.height ?? nodeHeight,
        });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);

    const layoutedElements = elements.map((el: any) => {
      if (isNode(el)) {
        const node = dagreGraph.node(el.id);
        return {
          ...el,
          targetPosition: isHorizontal ? Position.Left : Position.Top,
          sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
          position: {
            x: node.x - node.width / 2,
            y: node.y - node.height / 2,
          },
        };
      }

      return el;
    });
    dispatch(setElements(layoutedElements));
    isHorizontal
      ? dispatch(setWorkflowOrientation("horizontal"))
      : dispatch(setWorkflowOrientation("vertical"));
  };

  const onEdgeUpdate = (oldEdge: any, newConnection: any) => {
    dispatch(setElements(updateEdge(oldEdge, newConnection, elements)));
  };
  const edgeTypes: any = {
    custom: CustomEdge,
  };
  return (
    <ReactFlow
      onLoad={onLoad}
      elements={elements}
      onConnect={onConnect}
      onElementsRemove={onElementsRemove}
      onDrop={onDrop}
      onDragOver={onDragOver}
      nodeTypes={nodeTypes}
      onPaneClick={onPaneClick}
      onElementClick={onElementClick}
      onEdgeUpdate={onEdgeUpdate}
      edgeTypes={edgeTypes}
      className="react-flow-root">
      <Stack direction="row" className="custom-controls">
        <Stack direction="row" spacing={2}>
          <CustomButton
            disabled={!changeMade}
            onClick={() => {
              if (forms.length > 0) {
                const parsed = parseToBackend(
                  elements,
                  workflow,
                  forms[0].id,
                  isEdit
                );
                isEdit ? putWorkflows(parsed) : postWorkflows(parsed);
              }
            }}
            innerText={t("save")}
          />

          <CustomButton
            innerText={t("restore")}
            disabled={!isEdit}
            onClick={() => {
              if (workflow.id) {
                dispatch(getIndividualWorkflowAsync(workflow.id));
              }
            }}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <CustomButton
            innerText="horizontal"
            onClick={() => getLayoutedElements(elements, "LR")}
          />
          <CustomButton
            innerText="vertical"
            onClick={() => getLayoutedElements(elements, "TB")}
          />
        </Stack>
      </Stack>
      <Controls />
      <MiniMap />
      <Background color="#FFFFFF" />
      <MarkerDefinition id="edge-marker-base" color="grey" />
      <MarkerDefinition id="edge-marker-selected" color={colors.primaryColor} />
    </ReactFlow>
  );
};

export default WorkFlowZone;
