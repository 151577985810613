const scopeTypes = [
  { id: 0, displayName: "ideas" },
  { id: 1, displayName: "businessCase" },
  { id: 2, displayName: "projects" },
];
export const scopeTypeDisplayNameFromId = (id: number): string | undefined => {
  return scopeTypes.find((scopeToFind) => id === scopeToFind.id)?.displayName;
};
export const scopeTypeIdFromDisplayName = (
  displayName: string
): number | undefined => {
  return scopeTypes.find(
    (scopeToFind) => displayName === scopeToFind.displayName
  )?.id;
};
export default scopeTypes;
